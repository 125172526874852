@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rajdhani&display=swap");
*{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  margin: auto;
  background-color: #ffffff ;
  scroll-behavior: smooth;
}

.home header {
  display: flex;
  position: fixed;  
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #7fb26f;
  box-shadow: 5px 0px 5px;
  border-bottom: 1px solid #fff;  
  
}

.shadowText{
  text-shadow: 1px 1px 2px white;
}

.containHeader {
  display: flex;
  max-width: 1100px;
  width: 100%;
  justify-content: space-between; 
  padding: 0 20px;
}

.bgImage {
  position: absolute;
  width: 100vw;
  z-index: -1;
}

.bgImage img {
  width: 107%;
  filter: blur(3px);
}

/* .bgHome{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #8B8B8B;
  opacity: 25%;
  z-index: -1;
} */

.home header .containerLogo {
  display: flex;
  align-items: center;
}
.home header .containerLogo img {
  width: 40px;
  height: 40PX;
}

/* .home header .containerLogo h1 {
  font-size: 20px;
  margin: 0 5px;
} */

.home header .navDesktop ul {
  display: flex;
}
.home header .navDesktop ul li {
  list-style: none;
  margin: 5px;
}
.home header .navDesktop ul li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  padding: 5px;
  border-radius: 10px;
  position: relative;
}
.home header .navDesktop ul li .buttonNav::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #7fb26f;
  text-align: start;
  transition: 0.5s;
  top: 25px;
}
.home header .navDesktop ul li .buttonNav:hover::before {
  width: 85%;
}
.home header .navDesktop ul .lastButton a {
  color: #Fff;
  background-color: #7fb26f;
  border: solid 1px #7fb26f;
  transition: 0.2s;
}
.home header .navDesktop ul .lastButton a:hover {
  color: #7fb26f;
  background-color: #Fff;
}
.home header .navDesktop ul .lastButton a:active {
  color: #Fff;
  background-color: #7fb26f;
}

.sectionHome {
  position: relative;
  /* height: 400px; */
  max-width: 100vw;
  overflow: hidden;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}



.sectionHome .wave {
  position: absolute;
  top: -170px;
  z-index: -100;
  width: 1800px;
}
.sectionHome .conteinerHome {
  max-width: 1100px;
  width: 100vw;
  text-align: end;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sectionHome .conteinerHome img {
  width: 50%;
  height: 50%;
  max-width: 300px;
  margin: 0%;
}
.sectionHome .conteinerHome h2 {
  font-size: 60px;
  margin: 0;
  text-shadow: 2px 2px 2px #fff;
}
.sectionHome .conteinerHome div {
  width: 400px;
  float: right;
}
.sectionHome .conteinerHome div a {
  border: 1px solid #7fb26f;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: #7fb26f;
  color: #fff;
  text-shadow: 1px 1px #000;
  margin: 5px;
  text-decoration: none;
}
.sectionHome .conteinerHome div a:hover {
  color: #000;
  background-color: rgba(127, 178, 111, 0.5);
  text-shadow: 1px 1px #fff;
}

.menu {
  width: 30px;
  height: 30px;
  display: none;
  cursor: pointer;
  transition: 1s;
}

.closeMenu {
  width: 30px;
  height: 30px;
  display: none;
  cursor: pointer;
}
.closeMenu ul {
  position: absolute;
  top: 50px;
  z-index: 100;
  text-align: center;
  transition: 1s;
  right: 0;
  width: 100vw;
  background-color: #7FB26F;
  border-bottom: 1px solid #fff;  
}
.closeMenu ul li {
  list-style: none;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  background-color: #7fb26f;
  width: 100%;
}
.closeMenu ul li a {
  padding: 5px;
  text-decoration: none;
  color: #ffffff; 
}
.closeMenu ul li:hover {
  background-color: #fff;
  transition: 0.3s;
}

.closeMenu ul li:hover a {
  color: #000;
}

.services {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  /* height: 300px; */
  text-align: center;
  background-color: #7fb26f;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  clip-path: polygon(0% 100%,100% 90%,100% 0%,0% 0%);
}
.services .secondWave {
  position: absolute;
  z-index: -250;
  margin: auto;
  width: 1800px;
}
.services .articles {
  display: flex;
  text-align: center;
  max-width: 1100px;
  width: 100%;
  /* width: 80vw; */
  margin: 0 auto;
  margin-top: 30px;
  justify-content: space-between;
}
.services .articles article {
  max-width: 33.333%;
  padding: 15px;
}
.services .articles article img {
  width: 48px;
}
.services .articles article h3 {
  text-shadow: 2px 2px 1px #fff;
}

.about {
  text-align: center;
  max-width: 1100px;
  margin: 50px auto;
}

.aboutTitle{
  text-align: center;
  width: 100%;
  font-size: 28px;
  margin: 20px 0;
  font-weight: 700;
}

.service{
  width: 100%;
  max-width: 1100px;
  margin: auto;
  display: flex;
  overflow-x: scroll  ;
}

.service .containerCard{
  width: 2000px;
  height: 360px;
  margin: auto;
  display: flex;
}

.service .card{
  width:  300px;
  height: 350px;
  border: 5px solid #5A854D;
  background: #7FB26F;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.50);
  margin: 0 10px;
}

.service .card img{
  width: 100%;
  max-height: 280px;
  clip-path: polygon(0% 85%,100% 100%, 100% 0%,0% 0%);
}

.service .card div{
  background: #7FB26F;
}

.banner{
  width: 100%;
  /* height: 430px; */
  min-height: 340px;
  clip-path: polygon(0% 100%,100% 90%, 100% 0%,0% 10%);
  background-color: #7fb26f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.banner div{
  width: 100%;
  max-width: 1100px;
}

.banner div .point{
  color: #ffffff; 
  margin: 0; 
  font-size: 20px; 
  font-weight: 400;
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* width: 33%; */
  flex-direction: column;
  text-align: center;
}

.slide img {
  width: 100%;
  max-width: 150px;
  height: 50px;
  margin: auto;
}

.gallery {
  position: relative;
  overflow: hidden;
  display: flex;
  margin: auto;
  padding-bottom: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gallery .wave3 {
  position: absolute;
  z-index: -200;
  top: -270px;
  width: 1800px;
}
.gallery h3 {
  text-align: center;
  width: 100%;
  font-size: 36px;
  margin: 20px 0;
  font-weight: 700;
}
.gallery .album {
  width: 95%;
  max-width: 1000px;
}
.gallery .album div {
  text-align: center;
}
.gallery .album img {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  cursor: pointer;
}
.gallery .album .carousel-initialized {
  height: 630px;
}

.contact {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0px;
  background-color: #7fb26f;
  padding-top: 50px
}

.bgBlur{
  width: 100%;
  max-width: 1100px;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.12);
  box-shadow: -5px 5px 12px 1px rgba(0, 0, 0, 0.08) inset, 5px -5px 12px 0px rgba(0, 0, 0, 0.08) inset, 4px 7px 15px 0px rgba(0, 0, 0, 0.08), -1px 0px 2px 0px rgba(201, 201, 201, 0.10);
  backdrop-filter: blur(12.5px);
}

.bgBlur > div{
  display: flex;
}

.bgBlur > div > div{
  margin: 20px;
}

.modal{
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100%;
  background-color: #d3d3d3b5;
  max-width:  1800px;
}

.modal div{
  position: relative;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.imgSelect{
  position: absolute;
  width: 60%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  cursor: pointer;
  max-width: 800px;
}

.close{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  cursor: pointer;
}

.slick-dots{
  bottom: -40px !important;
}

.containForm div{ 
  max-width: 50%;
  min-width: 320px;
  width: 800px;
}

.containForm div p {
  color: #fff;
  font-size: 24px;
}

.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
  border-radius: 3px;
  overflow: hidden;
}

.inp .label {
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  pointer-events: none;
}

.inp .focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
}

.inp input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 16px 12px 0 12px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.02);
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.3);
  color: #ffffff;
  transition: all 0.15s ease;
}

.inp input:hover {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}

.inp input:not(:placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}

.inp input:focus {
  background: rgba(0, 0, 0, 0.05);
  outline: none;
  box-shadow: inset 0 -2px 0 #FFFFFF;
}

.inp input:focus + .label {
  color: #0077FF;
  transform: translate3d(0, -12px, 0) scale(0.75);
}

.inp input:focus + .label + .focus-bg {
  transform: scaleX(1);
  transition: all 0.1s ease;
}

.btnWp{
  background: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  height: 40px;
  margin: 5px 0;
  cursor: pointer;
}

.btnWp:hover{
  background: white;
  border: 1px solid transparent;
  color: #7FB26F;
}

@media only screen and (max-width: 970px) {
  .menu {
    display: flex;
    z-index: 200;
  }
  .closeMenu {
    display: flex;
    z-index: 200;
  }
  .navDesktop {
    display: none;
  }
  .sectionHome .conteinerHome {
    top: 20px;
  }
  .sectionHome .conteinerHome h2 {
    font-size: 50px;
  }
  .sectionHome .conteinerHome div {
    width: 300px;
  }
  .about .info .imageAbout {
    width: 200px;
    height: 200px;
  }
  .gallery {
    padding-bottom: 50px;
  }
  .gallery .album img {
    width: 80%;
  }
  .gallery .album .carousel-initialized {
    height: 480px;
  }
  .containForm{
    flex-direction: column;
  }
  .containForm div {
    margin: auto !important ;
  }
}
@media (max-width: 768px) {
  .bgImage img {
      display: none; /* Oculta la imagen grande en pantallas pequeñas */
  }

  .bgImage img.flex {
      display: block; /* Muestra la imagen pequeña en pantallas pequeñas */
  }
}
@media (min-width: 769px) {
  .bgImage img {
      display: block; /* Muestra la imagen grande en pantallas grandes */
  }
  .bgImage img.flex {
      display: none; /* Oculta la imagen pequeña en pantallas grandes */
  }
}
@media only screen and (max-width: 750px) {
  .sectionHome .conteinerHome p {
    margin: 0;
  }
  .gallery .album .carousel-initialized {
    height: 390px;
  }
  .contact .contenedorContact {
    flex-direction: column-reverse;
  }
  .contact .wave4 {
    top: -190px;
  }
  .bgImage {
    top: -300px;
  }
}
@media only screen and (max-width: 650px) {
  .imgSelect{
    width: 70%;
  }
  .conteinerHome {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
    top: -200px;
  }
  .conteinerHome div {
    position: relative;
    top: 230px;
  }
  .sectionHome .conteinerHome div {
    margin-bottom: 0;
    text-align: center;
  }
  .sectionHome .wave {
    top: -114px;
  }
  .sectionHome .conteinerHome {
    top: -260px;
  }
  .sectionHome .conteinerHome div button {
    background-color: #7fb26f;
    color: #fff;
    text-shadow: 1px 1px #000;
    margin: 5px;
  }
  .sectionHome .conteinerHome img {
    position: relative;
    top: 270px;
  }
  /* .services {
    height: 750px;
    padding-bottom: 60px;
  } */
  .services .articles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .services .articles article {
    min-width: 300px;
  }
  .banner{
    padding: 50px 0;
  }
  .about .info {
    flex-direction: column;
  }
  .about .info div {
    width: 80%;
  }
  .gallery .wave4 {
    bottom: -240px;
  }
  .gallery .album img {
    max-width: 150px;
    max-height: 150px;
  }
  .gallery .album .carousel-initialized {
    height: 300px;
  }
  /* .sectionHome h1{
    font-size: 60px !important;
  } */
  .containForm div img{
    max-width: 100%;  
  }
  .bgImage {
    top: -180px;
  }
}
@media only screen and (max-width: 500px) {
  .imgSelect{
    width: 85%;
  }
  .sectionHome .conteinerHome h2 {
    font-size: 40px;
  }
  .home header .containerLogo img {
    width: 25px;
    height: 25PX;
  }
  /* .home header .containerLogo h1 {
    font-size: 16px;
  } */
  .sectionHome .wave {
    top: -90px;
  }
  .gallery {
    padding-bottom: 33px;
    margin-bottom: 30px;
  }
  .gallery .wave3 {
    top: -52px;
    height: 500px;
  }
  .gallery .wave4 {
    bottom: -100px;
    height: 520px;
  }
  .gallery .album img {
    width: 80%;
  }
  .gallery .album .carousel-initialized {
    height: 450px;
  }
  .fondoGris {
    top: 300px;
  }
  .titleContact h3 {
    margin: 0;
  }
  .contact .wave4 {
    top: -230px;
  }
  .slick-dots{
    bottom: -66px !important;
  }
  .bgImage {
    top: -100px;
  }

}

@media only screen and (max-width: 350px) {
  .imgSelect{
    width: 95%;
  }

  .bgImage {
    top: -100px;
  }
}




