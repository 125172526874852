@import url("https://fonts.googleapis.com/css2?family=Rajdhani&display=swap");
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  margin: auto;
  background-color: #ffffff;
}

.home header {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #7fb26f;
}


.containHeader {
  display: flex;
  max-width: 1100px;
  width: 100%;
  justify-content: space-between;
}

.home header .containerLogo {
  display: flex;
  align-items: center;
}

.home header .containerLogo img {
  width: 40px;
  height: 40PX;
}

.home header .containerLogo h1 {
  font-size: 20px;
  margin: 0 5px;
}

.home header .navDesktop ul {
  display: flex;
}

.home header .navDesktop ul li {
  list-style: none;
  margin: 5px;
}

.home header .navDesktop ul li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  padding: 5px;
  border-radius: 10px;
  position: relative;
}

.home header .navDesktop ul li .buttonNav::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #7fb26f;
  text-align: start;
  transition: 0.5s;
  top: 25px;
}

.home header .navDesktop ul li .buttonNav:hover::before {
  width: 85%;
}

.home header .navDesktop ul .lastButton a {
  color: #Fff;
  background-color: #7fb26f;
  border: solid 1px #7fb26f;
  transition: 0.2s;
}

.home header .navDesktop ul .lastButton a:hover {
  color: #7fb26f;
  background-color: #Fff;
}

.home header .navDesktop ul .lastButton a:active {
  color: #Fff;
  background-color: #7fb26f;
}

.sectionHome {
  position: relative;
  height: 480px;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sectionHome .wave {
  position: absolute;
  top: -170px;
  z-index: -100;
  width: 1800px;
}

.sectionHome .conteinerHome {
  max-width: 1000px;
  width: 80vw;
  text-align: end;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionHome .conteinerHome img {
  width: 50%;
  height: 50%;
  max-width: 300px;
  margin: 0%;
}

.sectionHome .conteinerHome h2 {
  font-size: 60px;
  margin: 0;
  text-shadow: 2px 2px 2px #fff;
}

.sectionHome .conteinerHome div {
  width: 400px;
  float: right;
}

.sectionHome .conteinerHome div a {
  border: 1px solid #7fb26f;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: #7fb26f;
  color: #fff;
  text-shadow: 1px 1px #000;
  margin: 5px;
  text-decoration: none;
}

.sectionHome .conteinerHome div a:hover {
  color: #000;
  background-color: rgba(127, 178, 111, 0.5);
  text-shadow: 1px 1px #fff;
}

.menu {
  width: 30px;
  height: 30px;
  display: none;
  cursor: pointer;
  transition: 1s;
}

.closeMenu {
  width: 30px;
  height: 30px;
  display: none;
  cursor: pointer;
}

.closeMenu ul {
  position: relative;
  top: 15px;
  left: -230px;
  z-index: 100;
  text-align: center;
  transition: 1s;
}

.closeMenu ul li {
  list-style: none;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  background-color: #7fb26f;
  width: 175px;
  border: 1px solid #000;
}

.closeMenu ul li a {
  padding: 5px;
  text-decoration: none;
  color: #000;
}

.closeMenu ul li:hover {
  background-color: #fff;
  transition: 0.3s;
}

.services {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  height: 500px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.services .secondWave {
  position: absolute;
  z-index: -250;
  margin: auto;
  width: 1800px;
}

.services .articles {
  display: flex;
  text-align: center;
  max-width: 1000px;
  width: 80vw;
  margin: 0 auto;
  margin-top: 30px;
}

.services .articles article {
  max-width: 33.333%;
  padding: 15px;
}

.services .articles article img {
  width: 48px;
}

.services .articles article h3 {
  text-shadow: 2px 2px 1px #fff;
}

.about {
  text-align: center;
  max-width: 1000px;
  width: 80vw;
  margin: 50px auto;
}

.about .info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0;
}

.about .info .imageAbout {
  width: 300px;
  height: 300px;
  padding: 5px;
  border-radius: 150px;
  border: solid;
  overflow: hidden;
  position: relative;
}

.about .info .imageAbout img {
  position: absolute;
  width: 400px;
  top: 41px;
  left: -23px;
  padding: 5px;
}

.about .info div {
  max-width: 380px;
  width: 50%;
}

.slide img {
  width: 100%;
  max-width: 150px;
  height: 50px;
  margin: auto;
}

.gallery {
  position: relative;
  overflow: hidden;
  display: flex;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gallery .wave3 {
  position: absolute;
  z-index: -200;
  top: -270px;
  width: 1800px;
}

.gallery h3 {
  text-align: center;
  font-size: 30px;
}

.gallery .album {
  width: 95%;
  max-width: 1000px;
}

.gallery .album div {
  text-align: center;
}

.gallery .album img {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  cursor: pointer;
}

.gallery .album .carousel-initialized {
  height: 630px;
}

.fondoGris {
  background-color: #e6e6e6;
  width: 1800px;
  height: 500px;
  position: absolute;
  top: 600px;
  z-index: -250;
}

.contact {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
}

.contact .wave4 {
  position: absolute;
  width: 1800px;
  top: -150px;
  z-index: 300;
}

.contact .contenedorContact {
  background: #7fb26f;
  width: 1800px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 100px;
}

.contact .contenedorContact .infoContact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  grid-auto-rows: minmax(50px, auto);
  margin: 0 15px;
  align-items: center;
  grid-gap: 20px;
}

.contact .contenedorContact .infoContact .titleContact {
  font-size: 55px;
  margin: 0;
  grid-column: 1/3;
  grid-row: 1;
}

.contact .contenedorContact .infoContact .iconsContact {
  max-width: 48px;
  display: flex;
  align-items: center;
}

.contact .contenedorContact .infoContact .iconsContact a {
  transition: 0.5s;
  padding: 0 3.5px;
}

.contact .contenedorContact .infoContact .iconsContact a img {
  margin: 5px;
  width: 50px;
}

.contact .contenedorContact .infoContact .iconsContact a:hover {
  transform: translateY(-10px);
}

.contact .contenedorContact .infoContact .iconsContact a:last-child {
  padding: 0;
}

.contact .contenedorContact .infoContact .iconsContact a:last-child img {
  width: 65px;
}

.modal {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100%;
  background-color: rgba(211, 211, 211, 0.7098039216);
  max-width: 1800px;
}

.modal div {
  position: relative;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.imgSelect {
  position: absolute;
  width: 60%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  cursor: pointer;
  max-width: 800px;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  cursor: pointer;
}

.slick-dots {
  bottom: -40px !important;
}

@media only screen and (max-width: 970px) {
  .menu {
    display: flex;
    z-index: 200;
  }
  .closeMenu {
    display: flex;
    z-index: 200;
  }
  .navDesktop {
    display: none;
  }
  .sectionHome .conteinerHome {
    top: 20px;
  }
  .sectionHome .conteinerHome h2 {
    font-size: 50px;
  }
  .sectionHome .conteinerHome div {
    width: 300px;
  }
  .about .info .imageAbout {
    width: 200px;
    height: 200px;
  }
  .gallery {
    padding-bottom: 30px;
  }
  .gallery .album img {
    width: 80%;
  }
  .gallery .album .carousel-initialized {
    height: 480px;
  }
}
@media only screen and (max-width: 750px) {
  .sectionHome .conteinerHome p {
    margin: 0;
  }
  .gallery .album .carousel-initialized {
    height: 390px;
  }
  .contact .contenedorContact {
    flex-direction: column-reverse;
  }
  .contact .wave4 {
    top: -190px;
  }
}
@media only screen and (max-width: 650px) {
  .imgSelect {
    width: 70%;
  }
  .conteinerHome {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
    top: -200px;
  }
  .conteinerHome div {
    position: relative;
    top: 230px;
  }
  .sectionHome .conteinerHome div {
    margin-bottom: 0;
    text-align: center;
  }
  .sectionHome .wave {
    top: -114px;
  }
  .sectionHome .conteinerHome {
    top: -260px;
  }
  .sectionHome .conteinerHome div button {
    background-color: #7fb26f;
    color: #fff;
    text-shadow: 1px 1px #000;
    margin: 5px;
  }
  .sectionHome .conteinerHome img {
    position: relative;
    top: 270px;
  }
  .services {
    height: 750px;
  }
  .services .articles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .services .articles article {
    min-width: 300px;
  }
  .about .info {
    flex-direction: column;
  }
  .about .info div {
    width: 80%;
  }
  .gallery .wave4 {
    bottom: -240px;
  }
  .gallery .album img {
    max-width: 150px;
    max-height: 150px;
  }
  .gallery .album .carousel-initialized {
    height: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .imgSelect {
    width: 85%;
  }
  .sectionHome .conteinerHome h2 {
    font-size: 40px;
  }
  .home header .containerLogo img {
    width: 25px;
    height: 25PX;
  }
  .home header .containerLogo h1 {
    font-size: 16px;
  }
  .sectionHome .wave {
    top: -90px;
  }
  .gallery {
    padding-bottom: 33px;
  }
  .gallery .wave3 {
    top: -52px;
    height: 500px;
  }
  .gallery .wave4 {
    bottom: -100px;
    height: 520px;
  }
  .gallery .album img {
    width: 80%;
  }
  .gallery .album .carousel-initialized {
    height: 450px;
  }
  .fondoGris {
    top: 300px;
  }
  .titleContact h3 {
    margin: 0;
  }
  .contact .wave4 {
    top: -230px;
  }
  .slick-dots {
    bottom: -66px !important;
  }
}
@media only screen and (max-width: 350px) {
  .imgSelect {
    width: 95%;
  }
}


/*# sourceMappingURL=Styles.scss.map */
